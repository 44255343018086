<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 0 !important;">
    
    <!-- Contenedor con la información de las solicitudes del afiliado -->
    <v-container v-if="mostrarSolicitudes" class="container">
      <v-row justify="space-between" class="ma-0">
        <v-col cols="12" sm="12" md="12" style="max-height: 75vh; overflow-y: auto; padding-bottom: 2rem">
          <v-card v-for="item in listaDeSolicitudes" :key="item.id" class="mb-3 elevation-2">
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" md="6">
                  <!-- Número, tipo, estado y fecha de la solicitud -->
                  <v-row align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="5" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Solicitud: </span>
                        <span> {{ item.idSolicitud === undefined ? item.idSote : item.idSolicitud }} </span>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="7" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Tipo de solicitud: </span>
                        <span> {{ item.tipoSolicitud !== 'HOME_DELIVERY' ? 'Entrega programada' : 'Entrega a domicilio' }} </span>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="5" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Estado: </span>
                        <span :class="obtenerClaseEstado(item.estadoSolicitudEntrega)"> {{ traducirEstado(item.estadoSolicitudEntrega) }} </span>
                        <v-btn v-if="item.estadoSolicitudEntrega === 'REFUSED' && item.visibleCount !== 0" icon @mousedown.prevent="dialogoRechazado = true">
                          <v-icon color="#0d47a1">info</v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="7" align="left" class="mb-1">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Fecha de solicitud: </span>
                        <span> {{ formatearFecha(item.fechaSolicitud) }}</span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Valor de la cuota moderadora -->
                  <v-row v-if="item.tipoSolicitud === 'SCHEDULED_DELIVERY' && (item.estadoSolicitudEntrega === 'INVOICE' || item.estadoSolicitudEntrega === 'DISPACHED')" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Valor de la cuota: </span>
                        <span> {{ parseFloat(item.valorCuota).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Valor del domicilio -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.estadoSolicitudEntrega === 'DISPACHED'" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Valor del domicilio: </span>
                        <span> {{ parseFloat(item.valorDomicilio).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Total a Pagar -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.estadoSolicitudEntrega === 'DISPACHED'" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Total a pagar: </span>
                        <span> {{ (parseFloat(item.valorCuota) + parseFloat(item.valorDomicilio)).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Dirección de entrega a domicilio -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.direccionEntrega !== ''" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Dirección de domicilio: </span>
                        <span>{{ consultarTipoDirecion(item) }}</span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Dirección de entrega programada-->
                  <v-row v-if="item.tipoSolicitud === 'SCHEDULED_DELIVERY' && item.estadoSolicitudEntrega === 'RESOLVED' && item.bodegas.bodegasId.codigoBodega !== null && item.bodegas.bodegasId.codigoBodega !== 0" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Su fórmula será entregada en: </span>
                        <span> {{ item.bodegaConsultada }} {{ item.direccionBodega }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Notificación -->
                  <v-row v-if="(item.estadoSolicitudEntrega === 'CLOSE' || item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'NOVELTY') && item.descripcionMotivo" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-0">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Notificación: </span>
                        <span style="color: #0d47a1;">{{ item.descripcionMotivo }}</span>
                        <v-tooltip v-if="item.adjunto !== null && item.adjunto !== ''" right color="error">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="error" v-bind="attrs" v-on="on" @mousedown.prevent="verAdjuntoNotificacion(item)">
                              <v-icon color="red">picture_as_pdf</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver notificación</span>
                        </v-tooltip>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Observación complementaria en el rechazo de la solicitud -->
                  <v-row v-if="(item.estadoSolicitudEntrega === 'CLOSE' || item.estadoSolicitudEntrega === 'REFUSED') && item.observacionMotivo" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-0">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Observación: </span>
                        <span style="color: #0d47a1;">
                          {{ item.observacionMotivo.length > 50 ? item.observacionMotivo.substr(0, 47) + '...' : item.observacionMotivo }}
                        </span>
                        <v-btn v-if="item.observacionMotivo.length > 50" text small class="ver-observacion"
                          @click="mensajeObservacion = item.observacionMotivo; dialogMensajeObservacion = true;">
                          ver más
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Tabla con los archivos subidos -->
                <v-col cols="12" md="6">
                  <v-row no-gutters justify="space-around">
                    <v-col cols="12" md="12">
                      <v-simple-table dense class="table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-if="item.registroAfiliado.documentoDigital !== null && item.registroAfiliado.documentoDigital !== ''" width="25%" class="text-center black--text font-weight-black">
                                DOCUMENTO
                              </th>
                              <th v-if="item.nombreFormula && item.nombreFormula.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                FÓRMULA
                              </th>
                              <th v-if="item.nombreAutorizacion && item.nombreAutorizacion.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                AUTORIZACIÓN
                              </th>
                              <th v-if="item.nombreComprobante && item.nombreComprobante.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                PAGO
                              </th>
                            </tr>
                          </thead>
                          <tbody class="tbody">
                            <tr>
                              <td v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" class="text-center" width="25%">
                                <v-btn icon v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" @click="verDocumento(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.registroAfiliado.documentoDigital.trim() !== '' && item.registroAfiliado.documentoDigital !== null && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.documentoCorrecto !== true ? 'red' : 'success'">
                                  {{ item.documentoCorrecto !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreFormula && item.nombreFormula.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreFormula !== ''" @click="verFormula(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreFormula !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.formulaCorrecta !== true ? 'red' : 'success'">
                                  {{ item.formulaCorrecta !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreAutorizacion && item.nombreAutorizacion.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreAutorizacion !== ''" @click="verAutorizacion(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreAutorizacion !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.autorizacionCorrecta !== true ? 'red' : 'success'">
                                  {{ item.autorizacionCorrecta !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreComprobante && item.nombreComprobante.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreComprobante !== ''" @click="verComprobantePago(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreComprobante !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.comprobantePago !== true ? 'red' : 'success'">
                                  {{ item.comprobantePago !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Carga del documento faltante -->
              <v-row v-if="item.idMotivo && item.idMotivo === 18 && item.estadoSolicitudEntrega === 'REFUSED'" align="center" no-gutters class="encabezadoCard">
                <v-col cols="12" md="12" class="pa-0 ma-0">
                  <div v-for="(faltante, index) in item.documentoFaltante" :key="index">
                    <v-row v-if="item.visibilityFaltante[index]?.visible" class="pa-0 ma-0">
                      <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
                        <v-row class="ma-0">
                          <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
                            <span class="font-weight-black" style="color: black;">Adjunte la autorización médica</span>
                          </v-col>
                          <v-col cols="12" md="9" style="height: 4rem" class="pl-0">
                            <validation-provider v-slot="{ errors }" class="mr-4">
                              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," 
                                :ref="'documentoFaltanteFile' + index" 
                                prefix="Seleccionar archivo" 
                                prepend-icon :clearable="true" 
                                :truncate-length="sizeText" outlined dense 
                                @change="cargarDocumentoFaltante('documentoFaltante', index, item)"
                                :error-messages="item.formatoIncorrecto[index] || errors" 
                                @click:clear="borrarDocumento('documentoFaltante', index, item)">
                              </v-file-input>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="1" class="d-flex align-center pa-1 mb-2">
                        <v-row class="d-flex justify-center">
                          <v-col v-if="isFaltanteValid(index, item)" cols="12" md="12" class="d-flex justify-center pa-0 pt-1">
                            <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="borrarDocumentoIcono('documentoFaltante', index, item)">
                                  <v-icon medium color="error">delete</v-icon>
                                </v-btn>
                                <v-btn icon @mousedown.prevent="abrirDocumento('documentoFaltante', index, item)" v-bind="attrs" v-on="on">
                                  <v-icon medium color="#0d47a1">visibility</v-icon>
                                </v-btn>
                              </template>
                              <span>Ver autorización médica</span>
                            </v-tooltip>
                            <div v-else>
                              <v-btn icon @click="borrarDocumentoIcono('documentoFaltante', index, item)">
                                <v-icon medium color="error">delete</v-icon>
                              </v-btn>
                              <v-btn icon @click="abrirDocumento('documentoFaltante', index, item)">
                                <v-icon medium color="#0d47a1">visibility</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="todosLosFaltantesLlenos(item)" class="pa-0 ma-0 mt-2">
                    <v-col cols="12" md="12" class="d-flex justify-center pa-0">
                      <v-btn small rounded color="#0d47a1" @click="agregarFaltante(item)">
                        <span class="white--text">Agregar autorización</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="item.solicitudCompleta" class="pa-0 ma-0 mt-1">
                <v-col cols="12" md="12" class="d-flex justify-center pa-0">
                  <v-btn small rounded color="#0d47a1" @click="confirmarDocumento">
                    <span class="white--text">Reenviar solicitud</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!--Contenedor para cuando no hay solicitudes -->
    <v-container v-if="mostrarSolicitudes === false" style="height: 75vh;" class="d-flex justify-center align-center">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-row align="center" no-gutters>
            <v-col cols="12" md="12" sm="12" class="d-flex justify-center align-center">
              <v-icon large color="gray">feedback</v-icon>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <v-col cols="12" md="12" sm="12" class="d-flex justify-center align-center">
              <span style="color: gray"> No has realizado solicitudes.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Paginación-->
    <v-footer v-if="mostrarSolicitudes" class="pb-6 pagination-container">
      <v-row class="pagination d-flex justify-center pr-2">
        <v-col cols="12" md="4" class="d-flex justify-center pa-0 pr-1">
          <span class="pagination-text pt-1 pr-2">{{ inicioItem }} - {{ finItem }} de {{ totalPaginas }}</span>
          <v-btn small @click="irPrimeraPagina" :disabled="pagina === 1" icon>
            <v-icon>first_page</v-icon>
          </v-btn>
          <v-btn  small @click="anteriorPagina" :disabled="pagina === 1" icon>
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <span class="pagination-text pt-1">{{ pagina }}</span>
          <v-btn  small @click="siguientePagina" :disabled="pagina * itemsPorPagina >= totalPaginas" icon>
            <v-icon>chevron_right</v-icon>
          </v-btn>
          <v-btn small @click="irUltimaPagina" :disabled="pagina * itemsPorPagina >= totalPaginas" icon>
            <v-icon>last_page</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    
    <!-- Dialogo para ver el documento de identidad y remplazarlo si fue rechazado en la solicitud -->
    <v-dialog v-model="mostarDocumento" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <embed v-if="!screenSmaller" :src="pdfBase64" type="application/pdf" class="pdf-chrome"/>
          <div v-else ref="pdfContainer" class="pdf-chrome"></div>
          <!-- <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf> -->
        </v-card-text>
        <v-card-text>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar documento de identidad</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="documentoIdentidadFile" prefix="Seleccionar archivo" 
                prepend-icon :clearable="true" :truncate-length="sizeText" @change="cargarDocumento('documento', null)" 
                outlined dense :error-messages="formatoIncorrecto.documento || errors" @click:clear="borrarDocumento('documento', null, null)">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDialogoDoc('mostarDocumento')">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Dialogo para ver la fórmula médica y remplazarla si fue rechazada en la solicitud -->
    <v-dialog v-model="mostarFormula" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <embed v-if="!screenSmaller" :src="pdfBase64" type="application/pdf" class="pdf-chrome"/>
          <div v-else ref="pdfContainer" class="pdf-chrome"></div>
        </v-card-text>
        <v-card-text>
          <!-- <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf> -->
          <div v-if="mostrarBotones" class="">
            <div v-for="(formula, index) in documentos.formulaMedica" :key="index">
              <div v-if="formulaVisible[index]" class="pa-0 ma-0">
                <span v-if="index === 0" style="color: #0d47a1;">Reemplazar fórmula médica</span>
                <validation-provider v-slot="{ errors }">
                  <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," :ref="'formulaMedicaFile' + index" prefix="Seleccionar archivo" 
                    prepend-icon :clearable="true" :truncate-length="sizeText" @change="cargarDocumento('formulaMedica', index)" 
                    outlined dense :error-messages="formatoIncorrecto.formulaMedica[index] || errors" @click:clear="borrarDocumento('formulaMedica', index, null)">
                  </v-file-input>
                </validation-provider>
              </div>
            </div>
            <div v-if="todasFormulasLlenas" class="pa-0 ma-0 d-flex justify-center ">
              <v-btn small rounded color="#0d47a1" @click="agregarFormula">
                <span class="white--text">Agregar fórmula</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDialogoDoc('mostarFormula')">Cerrar</v-btn>
          <v-btn v-if="mostrarBotones" class="white--text mb-4 me-2" color="#0d47a1" rounded @click="solicitudCompleta('mostarFormula')">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Dialogo para ver la autorización médica y remplazarla si fue rechazada en la solicitud -->
    <v-dialog v-model="mostarAutorizacion" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <embed v-if="!screenSmaller" :src="pdfBase64" type="application/pdf" class="pdf-chrome"/>
          <div v-else ref="pdfContainer" class="pdf-chrome"></div>
        </v-card-text>
        <v-card-text>
          <div v-if="mostrarBotones">
            <div v-for="(autorizacion, index) in documentos.autorizacionMedica" :key="index">
              <div v-if="autorizacionVisible[index]" class="pa-0 ma-0">
                <span v-if="index === 0" style="color: #0d47a1;">Reemplazar autorización médica</span>
                <validation-provider v-slot="{ errors }">
                  <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," :ref="'autorizacionMedicaFile' + index" prefix="Seleccionar archivo" 
                    prepend-icon :clearable="true" :truncate-length="sizeText" @change="cargarDocumento('autorizacionMedica', index)" outlined dense 
                    :error-messages="formatoIncorrecto.autorizacionMedica[index] || errors" @click:clear="borrarDocumento('autorizacionMedica', index, null)">
                  </v-file-input>
                </validation-provider>
              </div>
            </div>
            <div v-if="todasAutorizacionesLlenas" class="pa-0 ma-0 d-flex justify-center ">
              <v-btn small rounded color="#0d47a1" @click="agregarAutorizacion">
                <span class="white--text">Agregar autorización</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDialogoDoc('mostarAutorizacion')">Cerrar</v-btn>
          <v-btn v-if="mostrarBotones" class="white--text mb-4 me-2" color="#0d47a1" rounded @click="solicitudCompleta('mostarAutorizacion')">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
    <!-- Dialogo para ver el comprobante de pago y remplazarlo si fue rechazado en la solicitud -->
    <v-dialog v-model="mostrarComprobante" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <embed v-if="!screenSmaller" :src="pdfBase64" type="application/pdf" class="pdf-chrome"/>
          <div v-else ref="pdfContainer" class="pdf-chrome"></div>
          <!-- <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf> -->
        </v-card-text>
        <v-card-text>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar comprobante de pago</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="comprobanteFile" prefix="Seleccionar archivo" 
                prepend-icon :clearable="true" :truncate-length="sizeText" @change="cargarDocumento('comprobante', null)" 
                outlined dense :error-messages="formatoIncorrecto.comprobante || errors" @click:clear="borrarDocumento('comprobante', null, null)">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDialogoDoc('mostrarComprobante')">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo de para ver el documento subido que remplazará el documento rechazado -->
    <v-dialog v-model="mostrarModal" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title>{{ dialogTitulo }}</v-card-title>
        <v-card-text>
          <template v-if="componenteDocumento === 'imagen'">
            <img v-if="extension !== 'tiff'" :src="documentoSeleccionado" width="100%">
            <canvas v-else ref="tiffCanvas" width="100%"></canvas>
          </template>
          <template v-else>
            <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf>
          </template>
          <!-- <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf> -->
        </v-card-text>
        
        <v-card-actions v-if="!documentoReemplazado" class="justify-end">
          <v-btn class="boton-actualizar mb-4" @click="cerrarModal">Cancelar</v-btn>
          <v-btn class="boton-actualizar mb-4 me-2" @click="confirmarDocumento">Confirmar</v-btn>
        </v-card-actions>
        <v-card-actions v-else class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="mostrarModal = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mensaje de alerta para cuando el cambio del documento rechazado es exitoso -->
    <template>
      <div v-if="alertaReemplazo" class="overlay">
        <v-alert v-model="alertaReemplazo" class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeReemplazo }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Dialogo para ver una nota cuando tenga una solicitud rechazada -->
    <v-dialog v-model="dialogoRechazado" max-width="36rem" scrollable persistent>
      <v-card>
        <v-card-title class="ma-0 pa-0 d-flex justify-end" style="background-color: #0d47a1">
          <v-icon class="ma-1" @click="dialogoRechazado = false" color="white">close</v-icon>
        </v-card-title>
        <v-card-text class="mt-3">
          <span style="color: black">
            <strong>Nota:</strong> Debe corregir el documento que fue rechazado, estará marcado con una <v-icon color="red">close</v-icon>
            <br>
            Para corregirlo debe seleccionar el <v-icon color="red">picture_as_pdf</v-icon> y subir el nuevo documento.
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- Mensaje de alerta para cuando hay una solicitud en proceso -->
    <template>
      <div v-if="validateProgress" class="overlay">
        <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
          <span class="d-flex justify-center">Solicitud en proceso</span>
        </v-alert>
      </div>
    </template>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
      <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
      <span>{{ mensajeNotificacion }}</span>
    </div>

    <!-- Dialogo para ver documento adjunto en la notificación -->
    <v-dialog v-model="dialogoAdjuntoNotificacion" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">Notificación</v-card-title>
        <v-card-text>
          <pdf :src="adjuntoPdf" :page="1"  :key="adjuntoPdf" width="100%"></pdf>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarAdjunto()">Cerrar</v-btn>
          <v-btn class="white--text mb-4" color="primary" rounded @click="descargarAdjunto()">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Campo para mostrar la observación en un dialogo cuando es muy larga -->
    <v-dialog v-model="dialogMensajeObservacion" max-width="32rem" scrollable>
      <v-card>
        <v-card-text class="pa-4">
          {{ mensajeObservacion }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-0">
          <v-btn color="error" text @click=" dialogMensajeObservacion = false">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import "jquery-ui/ui/widgets/resizable";
import $ from "jquery";
import moment from 'moment';
import pdf from 'vue-pdf';
import Tiff from 'tiff.js';
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/build/pdf'; 
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export default {
  name: 'Historial',
  components: {
    pdf,
    ValidationProvider,
  },

  data() {
    return {
      roles: Role,
      userRoles: {},
      usuario: '',
      eliminado: false,
      loading: false,
      headersRegistros: [
        { text: "NÚMERO", sortable: false, width: "2rem" },
        { text: "TIPO SOLICITUD", sortable: false, width: "11rem" },
        { text: "DOCUMENTO", align: "center", sortable: false, width: "7rem" },
        { text: "FÓRMULA MÉDICA", align: "center", sortable: false, width: "7rem" },
        { text: "AUTORIZACIÓN MÉDICA", align: "center", sortable: false, width: "7rem" },
        { text: "ESTADO", sortable: false, width: "5rem" },
        { text: "FECHA", sortable: false, width: "8rem" },
      ],
      listaDeSolicitudes: [],
      pagina: 1,
      itemsPorPagina: 10,
      totalPaginas: 0,
      footerPropslistaMedicos: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      mostarDocumento: false,
      mostarFormula: false,
      mostarAutorizacion: false,
      mostrarComprobante: false,
      modalTitulo: '',
      pdfBase64: null,
      itemActual: null,
      mostrarBotones: false,
      documentos: {
        documento: null,
        formulaMedica: [],
        autorizacionMedica: [],
        comprobante: null,
        documentoFaltante: [],
      },
      documentosTemporales: {
        documento: null,
        formulaMedica: [],
        autorizacionMedica: [],
        comprobante: null,
        documentoFaltante: [],
      },
      formatoIncorrecto: {
        documento: '',
        formulaMedica: [],
        autorizacionMedica: [],
        comprobante: '',
        documentoFaltante: [],
      },
      mostrarModal: false,
      dialogTitulo: '',
      documentoSeleccionado: null,
      tipoDocumentoActual: '',
      documentosRequeridos: '',
      sinDocumentosRequeridos: false,
      solicitudSeleccionada: {},
      componenteDocumento: null,
      mensajeReemplazo: '',
      alertaReemplazo: false,
      dialogoRechazado: false,
      iR: null,
      mostrarSolicitudes: false,
      estadoRegistro: null,
      tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
      screenSmaller: false,
      sizeText: null,
      validateProgress: false,
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
      documentoReemplazado: false,
      adjuntoPdf: null,
      dialogoAdjuntoNotificacion: false,
      extension: '',
      dialogMensajeObservacion: false,
      mensajeObservacion: '',
      visibilityFormula: [],
      visibilityAutorizacion: [],
    }
  },

  // Se inicializa la variable userRoles con los roles del usuario autenticado
  created() {
    this.userRoles = this.auth.roles;
    this.iR = this.$store.state.idRegistro;
    this.listarSolicitudes();
  },

  computed: {
    ...mapState(["auth", "enterprise", "idRegistro" ]),
    // Calcula el índice del primer elemento de la página actual
    inicioItem() {
      return (this.pagina - 1) * this.itemsPorPagina + 1;
    },
    // Calcula el índice del último elemento de la página actual
    finItem() {
      const fin = this.pagina * this.itemsPorPagina;
      return fin > this.totalPaginas ? this.totalPaginas : fin;
    },
    formulaVisible() {
      return this.visibilityFormula.map(item => item.visible);
    },
    autorizacionVisible(){
      return this.visibilityAutorizacion.map(item => item.visible);
    },
    isFormulaMedicaValid() {
      return (index) => {
        const formula = this.documentos.formulaMedica[index];
        return formula !== undefined && formula !== null && formula !== '';
      };
    },
    isAutorizacionMedicaValid() {
      return (index) => {
        const autorizacion = this.documentos.autorizacionMedica[index];
        return autorizacion !== undefined && autorizacion !== null && autorizacion !== '';
      };
    },
    todasFormulasLlenas() {
      const todasLlenas = this.visibilityFormula.every((item, index) => {
        if (item.visible) {
          const valorFormula = this.documentos.formulaMedica[index];
          return valorFormula !== null && valorFormula !== '';
        }
        return true;
      });

      const elementosVisibles = this.visibilityFormula.filter(item => item.visible).length;

      return todasLlenas && (elementosVisibles < 5 || this.documentos.formulaMedica.length < 5);
    },
    todasAutorizacionesLlenas() {
      const todasLlenas = this.visibilityAutorizacion.every((item, index) => {
        if (item.visible) {
          const valorFormula = this.documentos.autorizacionMedica[index];
          return valorFormula !== null && valorFormula !== '';
        }
        return true;
      });

      const elementosVisibles = this.visibilityAutorizacion.filter(item => item.visible).length;

      return todasLlenas && (elementosVisibles < 5 || this.documentos.autorizacionMedica.length < 5);
    },
  },

  watch: {
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
    window.setTimeout(function () {
      $(".left_resizable").resizable({
        resize: function (event, ui) {
          ui.size.width = ui.originalSize.width;
        },
      });
    }, 1500);
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  methods: {
    ...mapMutations([]),
    /**
     * Navega a la primera página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
     */
    irPrimeraPagina() {
      this.pagina = 1;
      this.listarSolicitudes();
    },
    /**
     * Navega a la página anterior del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
     */
    anteriorPagina() {
      if (this.pagina > 1) {
        this.pagina--;
        this.listarSolicitudes();
      }
    },
    /**
     * Navega a la siguiente página del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
     */
    siguientePagina() {
      if (this.pagina * this.itemsPorPagina < this.totalPaginas) {
        this.pagina++;
        this.listarSolicitudes();
      }
    },
    /**
     * Navega a la última página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
     */
    irUltimaPagina() {
      this.pagina = Math.ceil(this.totalPaginas / this.itemsPorPagina);
      this.listarSolicitudes();
    },
    /**
     * Cambia la cantidad de ítems por página y actualiza la lista de solicitudes.
     */
    cambiarItemsPorPagina() {
      this.listarSolicitudes();
    },
    /**
     * Muestra un mensaje de error cuando el formato del archivo es incorrecto.
     */
    mostrarMensajeErrorFormatoIncorrecto(type, index, item) {
      if (index !== null && item !== null && type === 'documentoFaltante') {
        this.$set(item.formatoIncorrecto, index, 'Formato incorrecto, debe subir un archivo pdf o una imagen .png, .jpg, .jpeg, .tiff');
      } else if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
        this.$set(this.formatoIncorrecto[type], index, 'Formato incorrecto, debe subir un archivo pdf o una imagen .png, .jpg, .jpeg, .tiff');
      } else {
        this.$set(this.formatoIncorrecto, type, 'Formato incorrecto, debe subir un archivo pdf o una imagen .png, .jpg, .jpeg, .tiff');
      }
    },
    /**
     * Carga el documento faltante.
     * 
     * @param {string} type - El tipo de documento que se está cargando.
     */
    cargarDocumentoFaltante(type, index, item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;

      const fileInput = event.target;

      if (fileInput && fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];

        const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

        if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
          this.mostrarMensajeErrorFormatoIncorrecto(type, index, item);
          return;
        }
      
        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64String = event.target.result;
          if (this.esImagen(base64String) || this.esPDF(base64String)) {
            if (this.esPDF(base64String)) {
              const pdfData = await fetch(base64String).then(res => res.arrayBuffer());
              const pdfDoc = await PDFDocument.load(pdfData);
              const firstPage = await PDFDocument.create();
              const [copiedPage] = await firstPage.copyPages(pdfDoc, [0]);
              firstPage.addPage(copiedPage);
              const pdfBytes = await firstPage.saveAsBase64({ dataUri: true });
              if (type === 'documentoFaltante' && index !== null && item !== null) {
                item.documentosTemporales[index] = pdfBytes;
              } 
            } else if (this.esImagen(base64String)) {
              if (type === 'documentoFaltante' && index !== null && item !== null) {
                item.documentosTemporales[index] = reader.result;
              } 
            }

            if (type === 'documentoFaltante' && index !== null && item !== null) {
              this.tipoDocumentoActual = `${type}-${index}`;
              this.$set(item.formatoIncorrecto, index, null);
            } 
            this.mostrarDocumento(type, index, item);
          } else {
            this.mostrarMensajeErrorFormatoIncorrecto(type, index, item);
          }
        };

        reader.readAsDataURL(file);
        fileInput.value = null;
      }
    },
    /**
     * Carga un documento de tipo especificado.
     * 
     * @param {string} type - El tipo de documento que se está cargando.
     */
    cargarDocumento(type, index) {
      const fileInput = event.target;
      this.documentoReemplazado = false;

      if (fileInput && fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];

        const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

        if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
          this.mostrarMensajeErrorFormatoIncorrecto(type, index, null);
          return;
        }
      
        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64String = event.target.result;
          if (this.esImagen(base64String) || this.esPDF(base64String)) {
            if (this.esPDF(base64String)) {
              const pdfData = await fetch(base64String).then(res => res.arrayBuffer());
              const pdfDoc = await PDFDocument.load(pdfData);
              const firstPage = await PDFDocument.create();
              const [copiedPage] = await firstPage.copyPages(pdfDoc, [0]);
              firstPage.addPage(copiedPage);
              const pdfBytes = await firstPage.saveAsBase64({ dataUri: true });
              if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
                this.documentosTemporales[type][index] = pdfBytes;
              } else {
                this.documentosTemporales[type] = pdfBytes;
              }
            } else if (this.esImagen(base64String)) {
              if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
                this.documentosTemporales[type][index] = reader.result;
              } else {
                this.documentosTemporales[type] = reader.result;
              }
            }

            if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
              this.tipoDocumentoActual = `${type}-${index}`;
              this.$set(this.formatoIncorrecto[type], index, null);
            } else {
              this.tipoDocumentoActual = type;
              this.$set(this.formatoIncorrecto, type, null);
            }
            this.mostrarDocumento(type, index, null);
          } else {
            this.mostrarMensajeErrorFormatoIncorrecto(type, index, null);
          }
        };

        reader.readAsDataURL(file);
        fileInput.value = null;
      }
    },
    /** Valida que la imagen sea correcta */
    esImagen(base64String) {
      return base64String.startsWith('data:image/jpeg;base64,/9j/') || 
        base64String.startsWith('data:image/jpg;base64,') ||
        base64String.startsWith('data:image/png;base64,iVBORw0KGgo') ||
        base64String.startsWith('data:image/tiff;base64,SUkq');
    },
    /** Valida que el pdf sea correcto */
    esPDF(base64String) {
      if (!base64String.startsWith('data:application/pdf;base64,')) {
        return false;
      }

      const binary = atob(base64String.split(',')[1]);
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }

      const pdfText = new TextDecoder().decode(bytes);

      return pdfText.includes('%PDF-');
    },
    /**
     * Muestra el siguiente campo para el documento faltante que estaba oculto, si existe.
     * Si no hay campos ocultos, añade uno nuevo a la lista de faltantes y lo marca como visible.
     */
    agregarFaltante(item) {
      if (!item.documentoFaltante) {
        this.$set(item, 'documentoFaltante', []);
      }
      if (!item.documentosTemporales) {
        this.$set(item, 'documentosTemporales', []);
      }
      if (!item.formatoIncorrecto) {
        this.$set(item, 'formatoIncorrecto', []);
      }
      if (!item.visibilityFaltante) {
        this.$set(item, 'visibilityFaltante', []);
      }

      const indiceOculto = item.visibilityFaltante.findIndex((v) => v.visible === false);
      if (indiceOculto !== -1) {
        this.$set(item.visibilityFaltante, indiceOculto, { ...item.visibilityFaltante[indiceOculto], visible: true });
      } else {
        item.documentoFaltante.push(null);
        item.visibilityFaltante.push({ visible: true, position: item.visibilityFaltante.length });
      }
    },
    /**
     * Verifica si un documento faltante en una posición específica del arreglo
     * está definido y no está vacío.
     * 
     * @param {Number} index - El índice del documento a verificar en el arreglo.
     * @param {Object} item - El objeto que contiene la lista de documentos faltantes.
     * @returns {Boolean} - Retorna `true` si el documento no es `undefined`, `null`, ni está vacío.
     */
    isFaltanteValid(index, item) {
      const faltante = item.documentoFaltante[index];
      return faltante !== undefined && faltante !== null && faltante !== '';
    },
    /**
     * Verifica si todos los documentos visibles en el array `documentoFaltante` 
     * han sido llenados (no son nulos ni están vacíos). 
     * También comprueba que el número de documentos visibles o en `documentoFaltante`
     * no supere 5.
     * 
     * @param {Object} item - El objeto que contiene la visibilidad de documentos y los documentos faltantes.
     * @returns {Boolean} - Retorna `true` si todos los documentos visibles están llenos y la cantidad de 
     *                      documentos visibles no supera 5.
     */
    todosLosFaltantesLlenos(item) {
      const todasLlenas = item.visibilityFaltante.every((visibleItem, index) => {
        if (visibleItem.visible) {
          const faltante = item.documentoFaltante[index];
          return faltante !== null && faltante !== '';
        }
        return true;
      });

      const elementosVisibles = item.visibilityFaltante.filter(visibleItem => visibleItem.visible).length;

      return todasLlenas && (elementosVisibles < 5 || item.documentoFaltante.length < 5);
    },
    /**
     * Muestra el siguiente campo de fórmula médica que estaba oculto, si existe.
     * Si no hay campos ocultos, añade uno nuevo a la lista de fórmulas médicas y lo marca como visible.
     */
    agregarFormula() {
      const indiceOculto = this.visibilityFormula.findIndex((item) => item.visible === false);

      if (indiceOculto !== -1) {
        this.$set(this.visibilityFormula, indiceOculto, { ...this.visibilityFormula[indiceOculto], visible: true });
      } else {
        this.documentos.formulaMedica.push(null);
        this.visibilityFormula.push({ visible: true, position: this.visibilityFormula.length });
      }
    },
    /**
     * Muestra el siguiente campo de autorización médica que estaba oculto, si existe.
     * Si no hay campos ocultos, añade uno nuevo a la lista de autorizaciones médicas y lo marca como visible.
     */
    agregarAutorizacion() {
      const indiceOculto = this.visibilityAutorizacion.findIndex((item) => item.visible === false);

      if (indiceOculto !== -1) {
        this.$set(this.visibilityAutorizacion, indiceOculto, { ...this.visibilityAutorizacion[indiceOculto], visible: true });
      } else {
        this.documentos.autorizacionMedica.push(null);
        this.visibilityAutorizacion.push({ visible: true, position: this.visibilityAutorizacion.length });
      }
    },
    /**
     * Reorganiza las posiciones de los campos visibles y ocultos.
     * Los campos visibles se reordenan desde el índice 0 en adelante y los ocultos desde el último índice hacia atrás.
     * 
     * @param campo La lista de campos que se deben reordenar.
     */
    reordenarPosiciones(campo) {
      let visibleIndex = 0;
      let hiddenIndex = campo.length - 1;
      campo.forEach((item, index) => {
        if (item.visible) {
          this.$set(campo, index, { ...item, position: visibleIndex });
          visibleIndex++;
        }
      });

      campo.forEach((item, index) => {
        if (!item.visible) {
          this.$set(campo, index, { ...item, position: hiddenIndex });
          hiddenIndex--;
        }
      });
    },
    /**
     * Confirma el documento seleccionado y realiza los cambios necesarios en la solicitud correspondiente.
     * 
     * Si no hay archivos faltantes, se procede a enviar la solicitud de cambio de documentos al servidor.
     * Si la solicitud fue exitosa, se muestra un mensaje de éxito y se actualiza la lista de solicitudes.
     * Si hay archivos faltantes, se muestra un mensaje.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    confirmarDocumento() {
      const [tipoDocumento, index] = this.tipoDocumentoActual.split('-');
      if (index !== undefined) {
        const idx = parseInt(index, 10);
        if (tipoDocumento === 'formulaMedica' || tipoDocumento === 'autorizacionMedica') {
          this.$set(this.documentos[tipoDocumento], idx, this.documentosTemporales[tipoDocumento][idx]);
        } else if (tipoDocumento === 'documentoFaltante') {
          this.$set(this.solicitudSeleccionada[tipoDocumento], idx, this.solicitudSeleccionada.documentosTemporales[idx]);
          this.solicitudCompleta(null);
        }
        this.mostrarModal = false;
        this.tipoDocumentoActual = '';
        return;
      } else {
        this.documentos[tipoDocumento] = this.documentosTemporales[tipoDocumento];
      }
      if (tipoDocumento === 'documentoFaltante') {
        this.solicitudCompleta(null);
      }
      const formulas = this.documentos.formulaMedica ? this.documentos.formulaMedica.filter(item => item !== null && item !== '') : [];
      const autorizaciones = this.documentos.autorizacionMedica ? this.documentos.autorizacionMedica.filter(item => item !== null && item !== '') : [];
      const faltantes = this.solicitudSeleccionada.documentoFaltante ? this.solicitudSeleccionada.documentoFaltante.filter(item => item !== null && item !== '') : [];
      let solicitudEntrega;
      if (tipoDocumento === 'documento') {
        this.solicitudSeleccionada.documentoCorrecto = true;
        this.solicitudSeleccionada.visibleCount --;
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento.replace(/^data:application\/pdf;base64,/, ''),
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : faltantes.length === 1 ? faltantes[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (formulas.length === 1) {
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas[0].replace(/^data:application\/pdf;base64,/, ''),
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (autorizaciones.length === 1) {
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones[0].replace(/^data:application\/pdf;base64,/, ''),
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (tipoDocumento === 'comprobante') {
        this.solicitudSeleccionada.comprobantePago = true;
        this.solicitudSeleccionada.visibleCount --;
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : faltantes.length === 1 ? faltantes[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, ''),
        };
      } else if (faltantes.length === 1) {
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: faltantes[0].replace(/^data:application\/pdf;base64,/, ''),
          nombreComprobante: this.documentos.comprobante!== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      }
      let formulasMedicas = [];
      let autorizacionesMedicas = [];
      if (formulas.length > 1) {
        formulasMedicas = formulas.map(item => item.replace(/^data:application\/pdf;base64,/, ''));
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : faltantes.length === 1 ? faltantes[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante!== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      }
      if (autorizaciones.length > 1) {
        autorizacionesMedicas = autorizaciones.map(item => item.replace(/^data:application\/pdf;base64,/, ''));
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : faltantes.length === 1 ? faltantes[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante!== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      }
      if (faltantes.length > 1) {
        autorizacionesMedicas = faltantes.map(item => item.replace(/^data:application\/pdf;base64,/, ''));
        solicitudEntrega = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: formulas.length === 1 ? formulas[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: autorizaciones.length === 1 ? autorizaciones[0].replace(/^data:application\/pdf;base64,/, '') : faltantes.length === 1 ? faltantes[0].replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante!== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      }
      solicitudEntrega = {
        idSolicitud: this.solicitudSeleccionada.idSolicitud,
        direccionEntrega: this.solicitudSeleccionada.direccionEntrega,
        ...solicitudEntrega,
      }
      let solicitudPayload = {
        solicitudEntrega,
        formulasMedicas,
        autorizacionesMedicas
      };
      const hayValoresValidos = this.solicitudSeleccionada.documentoFaltante && this.solicitudSeleccionada.documentoFaltante.some(item => item !== null && item !== '');
      const esMotivoRechazo18YDocumentoFaltante = this.solicitudSeleccionada.idMotivo === 18 && this.solicitudSeleccionada.documentoFaltante && this.solicitudSeleccionada.documentoFaltante.length > 0 && hayValoresValidos;
      const tieneVisibilidadCero = this.solicitudSeleccionada.visibleCount === 0 || this.solicitudSeleccionada.visibleCount === undefined;
      
      if ((tieneVisibilidadCero && esMotivoRechazo18YDocumentoFaltante) || (tieneVisibilidadCero && this.solicitudSeleccionada.idMotivo !== 18)) {
        this.validateProgress = true;
        this.$http.post(`msa-external/api/solicitudEntrega/editar-documentos`, solicitudPayload)
        .then((response) => {
          if (response.status === 200) {
            this.mensajeReemplazo = "La solicitud ha sido reenviada exitosamente.";
            this.alertaReemplazo = true;
            this.listarSolicitudes();
            this.reset();
            this.cerrarDocumento();
            this.solicitudSeleccionada = {};
            setTimeout(() => {
              this.alertaReemplazo = false;
              this.mensajeReemplazo = '';
            }, 3000);
          }
          this.validateProgress = false;
        }).catch((error) => {
          this.validateProgress = false;
          this.observarError(error);
        })
      } else {
        this.mensajeReemplazo = "Debe reemplazar el archivo faltante.";
        this.alertaReemplazo = true;
        this.mostrarModal = false;
        this.mostarDocumento = false;
        this.mostarFormula = false;
        this.mostarAutorizacion = false;
        this.mostrarComprobante = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        setTimeout(() => {
          this.alertaReemplazo = false;
          this.mensajeReemplazo = '';
        }, 3000);
      }
    },
    /**
     * Establece valores para definir si la solicitud esta completa a la hora de reemviarla.
     */
    solicitudCompleta(modal) {
      if (this.solicitudSeleccionada) {
        if (this.solicitudSeleccionada.estadoSolicitudEntrega === 'REFUSED') {
          if (this.solicitudSeleccionada.formulaCorrecta === false && modal === 'mostarFormula') {
            const hayValoresValidos = this.documentos.formulaMedica.some(item => item !== null && item !== '');
            if (hayValoresValidos) {
              if (this.solicitudSeleccionada.visibleCount > 0 ) { this.solicitudSeleccionada.visibleCount --; }
            }
            this[modal] = false;
          } else if (this.solicitudSeleccionada.autorizacionCorrecta === false && modal === 'mostarAutorizacion') {
            const hayValoresValidos = this.documentos.autorizacionMedica.some(item => item !== null && item !== '');
            if (hayValoresValidos) {
              if (this.solicitudSeleccionada.visibleCount > 0 ) { this.solicitudSeleccionada.visibleCount --; }
            }
            this[modal] = false;
          } 
          const hayValoresValidos = this.solicitudSeleccionada.documentoFaltante && this.solicitudSeleccionada.documentoFaltante.some(item => item !== null && item !== '');
          const esMotivoRechazo18YDocumentoFaltante = this.solicitudSeleccionada.idMotivo === 18 && this.solicitudSeleccionada.documentoFaltante && this.solicitudSeleccionada.documentoFaltante.length > 0 && hayValoresValidos;
          const [tipoDocumento, index] = this.tipoDocumentoActual.split('-');
          if (index !== undefined && tipoDocumento === 'documentoFaltante' && modal === null) {
            this.tipoDocumentoActual = '';
          }
          const tieneVisibilidadCero = this.solicitudSeleccionada.visibleCount === 0 || this.solicitudSeleccionada.visibleCount === undefined;
          if ((tieneVisibilidadCero && esMotivoRechazo18YDocumentoFaltante) || (tieneVisibilidadCero && this.solicitudSeleccionada.idMotivo !== 18)) {
            this.$set(this.solicitudSeleccionada, 'solicitudCompleta', true);
          }
        }
      }
    },
    /**
     * Abre el modal para mostrar el documento correspondiente.
     * 
     * @param {string} tipoDocumento - Tipo de documento a mostrar ('documentoFaltante').
     */
    abrirDocumento(tipoDocumento, index, item) {
      this.documentoReemplazado = true;
      this.mostrarDocumento(tipoDocumento, index, item);
    },
    /**
     * Borra el documento seleccionado del tipo especificado.
     * 
     * @param {string} type - Tipo de documento a borrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    borrarDocumento(type, index, item) {
      if (type === 'documentoFaltante' && index !== null && item !== null) {
        this.borrarDocumentoFatante(index, item);
      } else if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
        this.borrarFormulaOrAutorizacion(type, index);
      } else {
        this.documentos[type] = null;
        this.$set(this.formatoIncorrecto, type, null);
      }
    },
    /**
     * Borra el valor de un documento faltante y su formato incorrecto en una posición específica.
     * Además, ajusta la visibilidad de los campos en función de los valores nulos o vacíos
     * y actualiza referencias del documento faltante.
     * 
     * @param {Number} index - El índice del documento a borrar en el array `documentoFaltante`.
     * @param {Object} item - El objeto que contiene los datos del documento faltante y su visibilidad.
     */
    borrarDocumentoFatante(index, item) {
      this.$set(item.documentoFaltante, index, null);
      this.$set(item.formatoIncorrecto, index, null);

      const nullOrEmptyItems = item.documentoFaltante
      .map((item, idx) => ({ item, idx }))
      .filter(entry => entry.item === null || entry.item === '');
      
      const nullOrEmptyCount = nullOrEmptyItems.length;

      if (nullOrEmptyCount > 1) {
        const campo = item.visibilityFaltante;
        const visibleItems = nullOrEmptyItems.filter(entry => campo[entry.idx].visible === true);
        if (visibleItems.length > 0) {
          const ultimoIndiceVisible = visibleItems[visibleItems.length - 1].idx;
          this.$set(campo, ultimoIndiceVisible, { ...campo[ultimoIndiceVisible], visible: false });
        }
      }

      this.actualizarReferenciasFaltante(item);
      
      const hayValoresValidos = item.documentoFaltante && item.documentoFaltante.some(item => item !== null && item !== '');
      const documentoFaltante = item.documentoFaltante && item.documentoFaltante.length > 0 && hayValoresValidos;
      if (item.idMotivo === 18) {
        if (!documentoFaltante) {
          this.$set(item, 'solicitudCompleta', false);
        } 
      }
    },
    /**
     * Actualiza las referencias a los campos de documento faltante, 
     * restableciendo los que estén vacíos o nulos.
     * 
     * @param {Object} item - El objeto que contiene los documentos faltantes.
     */
    actualizarReferenciasFaltante(item) {
      this.$nextTick(() => {
        if (item && item.documentoFaltante) {
          item.documentoFaltante.forEach((item, index) => {
            if (item === null || item === '') {
              const ref = 'documentoFaltanteFile';
              if (ref !== null) {
                const refDocumento = this.$refs[ref + index];
                if (refDocumento && refDocumento[0]) {
                  refDocumento[0].reset(); 
                }
              }
            }
          });
        }
      });
    },
    /**
     * Elimina un campo de fórmula médica o autorización médica o faltante y actualiza las referencias.
     * Si hay más de un campo vacío o nulo, oculta el último campo visible.
     *
     *  @param type El tipo de documento ('formulaMedica' o 'autorizacionMedica' o 'documentoFaltante').
     * @param index El índice del campo a eliminar.
     */
    borrarFormulaOrAutorizacion(type, index) {
      this.$set(this.documentos[type], index, null);
      this.$set(this.formatoIncorrecto[type], index, null);

      const nullOrEmptyItems = this.documentos[type]
      .map((item, idx) => ({ item, idx }))
      .filter(entry => entry.item === null || entry.item === '');
      
      const nullOrEmptyCount = nullOrEmptyItems.length;

      if (nullOrEmptyCount > 1) {
        const campo = type === 'formulaMedica' ? this.visibilityFormula : this.visibilityAutorizacion;
        const visibleItems = nullOrEmptyItems.filter(entry => campo[entry.idx].visible === true);
        if (visibleItems.length > 0) {
          const ultimoIndiceVisible = visibleItems[visibleItems.length - 1].idx;
          this.ocultarInput(campo, ultimoIndiceVisible);
        }
      }

      this.actualizarReferencias(type);
      
      if (this.solicitudSeleccionada.estadoSolicitudEntrega === 'REFUSED') {
        if (this.solicitudSeleccionada.formulaCorrecta === false && type === 'formulaMedica') {
          const hayValoresValidos = this.documentos.formulaMedica.some(item => item !== null && item !== '');
          if (!hayValoresValidos) {
            this.solicitudSeleccionada.visibleCount ++;
          }
        } else if (this.solicitudSeleccionada.autorizacionCorrecta === false && type === 'autorizacionMedica') {
          const hayValoresValidos = this.documentos.autorizacionMedica.some(item => item !== null && item !== '');
          if (!hayValoresValidos) {
            this.solicitudSeleccionada.visibleCount ++;
          }
        } 
        if (this.solicitudSeleccionada.visibleCount !== 0) {
          this.$set(this.solicitudSeleccionada, 'solicitudCompleta', false);
        }
      }
    },
    /**
     * Oculta un campo específico en la interfaz.
     * Actualiza el estado del campo y reordena las posiciones visibles y ocultas.
     * 
     * @param campo La lista de campos a actualizar.
     * @param index El índice del campo que se va a ocultar.
     */
    ocultarInput(campo, index) {
      this.$set(campo, index, { ...campo[index], visible: false });
      this.reordenarPosiciones(campo);
    },
    /**
     * Actualiza las referencias de archivos para los campos de autorización o fórmula médica o faltante.
     * Resetea los campos si están vacíos o nulos.
     * 
     * @param type El tipo de campo ('autorizacionMedica' o 'formulaMedica' o 'documentoFaltante').
     */
    actualizarReferencias(type) {
      this.$nextTick(() => {
        this.documentos[type].forEach((item, index) => {
          if (item === null || item === '') {
            const ref = type === 'autorizacionMedica' ? 'autorizacionMedicaFile' : type === 'formulaMedica' ? 'formulaMedicaFile' : 'documentoFaltanteFile';
            if (ref !== null) {
              const refDocumento = this.$refs[ref + index];
              if (refDocumento && refDocumento[0]) {
                refDocumento[0].reset(); 
              }
            }
          }
        });
      });
    },
    /**
     * Borra el documento seleccionado del tipo especificado por medio del icono 'delete'.
     * 
     * @param {string} type - Tipo de documento a borrar ('documentoFaltante').
     */
    borrarDocumentoIcono(type, index, item) {
      if (type === 'documentoFaltante' && index !== null && item !== null) {
        this.borrarDocumentoFatante(index, item);
      }
    },
    /**
     * Cierra el modal de visualización de documento y restablece los valores relacionados.
     * Reinicia los campos de carga de archivo según el tipo de documento y restablece el documento temporal y el tipo de documento actual.
     */
    cerrarModal() {
      const [tipoDocumento, index] = this.tipoDocumentoActual.split('-');
      if (index !== undefined) {
        const idx = parseInt(index, 10);
        if (tipoDocumento === 'documentoFaltante' && index !== null && this.solicitudSeleccionada !== null) {
          this.borrarDocumentoFatante(index, this.solicitudSeleccionada);
          this.solicitudSeleccionada[index] = null;
        }
        if (tipoDocumento === 'formulaMedica' || tipoDocumento === 'autorizacionMedica') {
          this.borrarFormulaOrAutorizacion(tipoDocumento, idx);
          this.documentosTemporales[tipoDocumento][index] = null;
        }
      } else {
        const refDocumento = this.$refs.documentoIdentidadFile;
        const refComprobante = this.$refs.comprobanteFile;

        if (tipoDocumento === 'documento' && refDocumento) {
          refDocumento.reset();
          this.documentos.documento = null;
        }  else if (tipoDocumento === 'comprobante' && refComprobante) {
          refComprobante.reset();
          this.documentos.comprobante = null;
        } 
        this.documentosTemporales[tipoDocumento] = null;
      }
      this.mostrarModal = false;
      this.tipoDocumentoActual = '';
    },
    /**
     * Muestra el documento seleccionado para su visualización en el modal correspondiente.
     * 
     * @param {string} type - El tipo de documento a mostrar.
     */
    mostrarDocumento(type, index, item) {
      this.dialogTitulo = `Verifique que el nuevo documento sea legible.`;
      if (index !== null && item !== null && type === 'documentoFaltante') {
        this.tipoDocumentoActual = `${type}-${index}`;
        if (item.documentosTemporales[index] !== null) {
          this.documentoSeleccionado = item.documentosTemporales[index];
          const extension = item.documentosTemporales[index].split(';')[0].split('/')[1];
          this.determinarComponenteDocumento(extension);
        } else {
          this.documentoSeleccionado = item.documentoFaltante[index];
        }
      } else if ((type === 'formulaMedica' || type === 'autorizacionMedica') && index !== null) {
        this.tipoDocumentoActual = `${type}-${index}`;
        if (this.documentosTemporales[type][index] !== null) {
          this.documentoSeleccionado = this.documentosTemporales[type][index];
          const extension = this.documentosTemporales[type][index].split(';')[0].split('/')[1];
          this.determinarComponenteDocumento(extension);
        } else {
          this.documentoSeleccionado = this.documentos[type][index];
        }
      } else {
        this.tipoDocumentoActual = type;
        if (this.documentosTemporales[type] !== null) {
          this.documentoSeleccionado = this.documentosTemporales[type];
          const extension = this.documentosTemporales[type].split(';')[0].split('/')[1];
          this.determinarComponenteDocumento(extension);
        } else {
          this.documentoSeleccionado = this.documentos[type];
        }
      }
      this.mostrarModal = true;
    },
    /**
     * Determina el tipo de componente a renderizar en función de la extensión del documento.
     * Si es un PDF, selecciona el componente `pdf`. Si es una imagen (jpg, png, jpeg, tiff),
     * selecciona el componente `imagen`. Si es `tiff`, llama a la función `renderTiff`.
     * 
     * @param {String} extension - La extensión del archivo del documento (e.g., 'pdf', 'jpg', 'png').
     */
    determinarComponenteDocumento(extension) {
      this.extension = extension;
      if (extension === 'pdf') {
        this.componenteDocumento = 'pdf';
      } else if (['jpg', 'png', 'jpeg', 'tiff'].includes(extension)) {
        this.componenteDocumento = 'imagen';
        if (extension === 'tiff') {
          this.$nextTick(() => this.renderTiff());
        }
      } else {
        this.componenteDocumento = null;
      }
    },
    /**
     * Renderiza un archivo TIFF en un canvas.
     */
    renderTiff() {
      const canvas = this.$refs.tiffCanvas;
      const tiff = new Tiff({ buffer: this.dataURItoBuffer(this.documentoSeleccionado) });
      const context = canvas.getContext('2d');
      canvas.width = tiff.width();
      canvas.height = tiff.height();
      context.drawImage(tiff.toCanvas(), 0, 0);
    },
    /**
     * Convierte un Data URI a un ArrayBuffer.
     * 
     * @param dataURI - El Data URI que representa la imagen en formato base64.
     * @returns {Uint8Array} - Un array de bytes que representa la imagen.
     */
    dataURItoBuffer(dataURI) {
      const base64 = dataURI.split(',')[1];
      const binary = atob(base64);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Uint8Array(array);
    },
    /**
     * Realiza una solicitud al servidor para obtener el historial de solicitudes de entrega del afiliado.
     * Se muestra el historial de solicitudes y se realiza el procesamiento de documentos y bodegas para cada solicitud.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    listarSolicitudes() {
      this.loading = true;
      const idAfiliado = this.iR;
      this.$http.get(`msa-external/api/solicitudEntrega/historial`, {
        params: {
          idAfiliado: idAfiliado,
          page: this.pagina - 1,
          size: this.itemsPorPagina,
        },
      }).then(response => {
        if (response.data.body.content && response.data.body.content.length > 0) {
          this.listaDeSolicitudes = response.data.body.content.sort((a, b) => b.idSolicitud - a.idSolicitud);
          this.totalPaginas = response.data.body.totalElements;
          this.mostrarSolicitudes = true;
          this.listaDeSolicitudes.forEach(item => {
            this.documentosACorregir(item);
            this.motivosRechazo(item);
          });
          this.listaDeSolicitudes.forEach(item => {
            this.bodegasSolicitud(item);
          });
        } else {
          this.$http.get(`msa-external/api/solicitud-temporal/historial`, {
            params: {
              idAfiliado: idAfiliado,
              page: this.pagina - 1,
              size: this.itemsPorPagina,
            },
          }).then(response => {
            if (response.data.body.content && response.data.body.content.length > 0) {
              this.listaDeSolicitudes = response.data.body.content.sort((a, b) => b.idSolicitud - a.idSolicitud);
              this.totalPaginas = response.data.body.totalElements;
              this.mostrarSolicitudes = true;
              this.listaDeSolicitudes.forEach(item => {
                this.documentosACorregir(item);
              });
              this.listaDeSolicitudes.forEach(item => {
                this.bodegasSolicitud(item);
              });
            } else {
              this.mostrarSolicitudes = false;
            }
          }).catch(error => {
            this.observarError(error);
            this.loading = false;
          })
        }
      }).catch(error => {
        this.observarError(error);
        this.loading = false;
      })
    },
    /**
     * Procesa los documentos para una solicitud específica y cuenta cuántos documentos incorrectos hay.
     */
    documentosACorregir(item) {
      this.estadoRegistro = item.registroAfiliado.estadoRegistro;
      if (item.estadoSolicitudEntrega === 'REFUSED') {
        const visibleCount = [
          { value: item.registroAfiliado.documentoDigital, correct: !item.documentoCorrecto },
          { value: item.nombreFormula, correct: !item.formulaCorrecta },
          { value: item.nombreAutorizacion, correct: !item.autorizacionCorrecta },
          { value: item.nombreComprobante, correct: !item.comprobantePago }
        ].filter(td => this.docIncorrecto(td)).length;
        this.$set(item, 'visibleCount', visibleCount);
        return visibleCount === 4;
      }
    },
    /**
     * Verifica si un documento es incorrecto.
     */
    docIncorrecto(td) {
      return td.value !== '' && td.value !== null && td.correct;
    },
    /**
     * Obtiene información sobre la bodega relacionada con una solicitud de entrega programada.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    bodegasSolicitud(item) {
      if (item.estadoSolicitudEntrega === 'RESOLVED' && item.tipoSolicitud === "SCHEDULED_DELIVERY" && item.bodegas !== null && item.bodegas.bodegasId.codigoBodega !== 0 ) {
        this.$http.get(`msa-external/api/bodegas/1/${item.bodegas.bodegasId.codigoBodega}`)
        .then((response) => {
          const bodegas = response.data;
          this.$set(item, 'bodegaConsultada', bodegas.nombreBodega);
          this.$set(item, 'direccionBodega', bodegas.direccion);
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Consulta los motivos de rechazo de las solicitudes en estado novedad y en estado rechazadas.
     * Almacena los motivos en variables internas dentro de cada solicitud para permitir su visualización.
     * @param item - La solicitud seleccionada.
     */
    motivosRechazo(item) {
      if (item.estadoSolicitudEntrega === 'NOVELTY') {
        this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'R'}`)
        .then((response) => {
          this.$set(item, 'descripcionMotivo', response.data[0].descripcionMotivo);
          this.$set(item, 'adjunto', response.data[0].adjunto);
          this.$set(item, 'idMotivo', response.data[0].idMotivo);
        }).catch(error => {
          this.observarError(error);
        })
      } else if (item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'CLOSE') {
        this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'I'}`)
        .then((response) => {
          let descripcionMotivo = '';
          let adjunto = '';
          let idMotivo = '';
          let observacion = null;
          
          if (response.data !== null && response.data !== "") {
            response.data.forEach((motivo, index) => {
              descripcionMotivo += motivo.descripcionMotivo;
              if (index < response.data.length - 1) {
                descripcionMotivo += ', ';
              }
              if (!adjunto && motivo.adjunto) {
                adjunto = motivo.adjunto;
              }
              if (motivo.idMotivo === 18) {
                idMotivo = 18;
              }
              if (motivo.observacion && motivo.observacion.trim() !== '') {
                observacion = motivo.observacion;
              }
            });
          }
          this.$set(item, 'descripcionMotivo', descripcionMotivo);
          this.$set(item, 'adjunto', adjunto);
          this.$set(item, 'idMotivo', idMotivo);
          this.$set(item, 'observacionMotivo', observacion);
          if (item.idMotivo && item.idMotivo === 18 && item.estadoSolicitudEntrega === 'REFUSED') {
            this.agregarFaltante(item);
          }
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Formatea la fecha en el formato 'DD-MM-YYYY h:mm a'.
     * 
     * @param {Date} fecha - La fecha a formatear.
     * @returns {string} La fecha formateada.
     */
    formatearFecha(fecha) {
      return moment(fecha).format('DD-MM-YYYY h:mm a');
    },
    /**
     * Traduce el estado de la solicitud al español.
     * 
     * @param {string} estado - El estado de la solicitud.
     * @returns {string} El estado traducido o el estado original si no se encuentra una traducción.
     */
    traducirEstado(estado) {
      const traducciones = {
        'CREATE': 'Creada',
        'VALIDATED': 'Validada',
        'INVOICED': 'Facturada',
        'DISPATCHED': 'Despachada',
        'RESOLVED': 'Resuelta',
        'REFUSED': 'Rechazada',
        'CLOSE': 'Cerrada',
        'OPEN': 'Abierta',
        'NOVELTY': 'Novedad',
      };
      return traducciones[estado] || estado;
    },
    /**
     * Muestra el documento de identidad asociado a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verDocumento(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.documento !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('documento', null, null);
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.registroAfiliado.documentoDigital}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then(response => {
          this.actualizarVistaDocumento(item);
          if (!this.screenSmaller) {
            this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
          } else {
            setTimeout(() => {
              this.embedPDF(response.data);
            }, 500);
          }
        }).catch(error => {
          if (item.estadoSolicitudEntrega === 'REFUSED') {
            this.actualizarVistaDocumento(item);
          }
          this.observarError(error);
        })
      }
    },
    /**
     * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
     */
    actualizarVistaDocumento(item) {
      this.modalTitulo = `Documento de identidad`;
      this.mostarDocumento = true;
      if (item.documentoCorrecto === false && item.estadoSolicitudEntrega === 'REFUSED') {
        this.mostrarBotones = true;
      } else {
        this.mostrarBotones = false;
      }
    },
    /**
     * Muestra la fórmula médica asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verFormula(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      this.pdfBase64 = null;
      this.modalTitulo = '';
      this.itemActual = item;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
      const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
      const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreFormula}`;
      this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
      .then(response => {
        this.actualizarVistaFormula(item);
        if (!this.screenSmaller) {
          this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
        } else {
          setTimeout(() => {
            this.embedPDF(response.data);
          }, 500);
        }
      }).catch(error => {
        if (item.estadoSolicitudEntrega === 'REFUSED') {
          this.actualizarVistaFormula(item);
        }
        this.observarError(error);
      })
    },
    /**
     * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
     */
    actualizarVistaFormula(item) {
      this.modalTitulo = `Fórmula médica`;
      this.mostarFormula = true;
      if (item.formulaCorrecta === false && item.estadoSolicitudEntrega === 'REFUSED') {
        this.mostrarBotones = true;
        if (!this.documentos.formulaMedica || this.documentos.formulaMedica.length === 0) {
          this.agregarFormula();
        }
      } else {
        this.mostrarBotones = false;
      }
    },
    /**
     * Muestra la autorización médica asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verAutorizacion(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      this.pdfBase64 = null;
      this.modalTitulo = '';
      this.itemActual = item;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
      const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
      const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreAutorizacion}`;
      this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
      .then(response => {
        this.actualizarVistaAutorizacion(item);
        if (!this.screenSmaller) {
          this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
        } else {
          setTimeout(() => {
            this.embedPDF(response.data);
          }, 500);
        }
      }).catch(error => {
        if (item.estadoSolicitudEntrega === 'REFUSED') {
          this.actualizarVistaAutorizacion(item);
        }
        this.observarError(error);
      })
    },
    /**
     * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
     */
    actualizarVistaAutorizacion(item) {
      this.modalTitulo = `Autorización médica`;
      this.mostarAutorizacion = true;
      if (item.autorizacionCorrecta === false && item.estadoSolicitudEntrega === 'REFUSED') {
        this.mostrarBotones = true;
        if (!this.documentos.autorizacionMedica || this.documentos.autorizacionMedica.length === 0) {
          this.agregarAutorizacion();
        }
      } else {
        this.mostrarBotones = false;
      }
    },
    /**
     * Muestra el comprobante de pago asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verComprobantePago(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.comprobante !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('comprobante', null, null);
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreComprobante}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then(response => {
          this.actualizarVistaComprobante(item);
          if (!this.screenSmaller) {
            this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
          } else {
            setTimeout(() => {
              this.embedPDF(response.data);
            }, 500);
          }
        }).catch(error => {
          if (item.estadoSolicitudEntrega === 'REFUSED') {
            this.actualizarVistaComprobante(item);
          }
          this.observarError(error);
        })
      }
    },
    /**
     * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
     */
    actualizarVistaComprobante(item) {
      this.modalTitulo = `Comprobande de pago`;
      this.mostrarComprobante = true;
      if (item.comprobantePago === false && item.estadoSolicitudEntrega === 'REFUSED') {
        this.mostrarBotones = true;
      } else {
        this.mostrarBotones = false;
      }
    },
    /**
     * Permite visualizar el adjunto de la notificación.
     * 
     * @param item - La solicitud seleccionada.
     */
    verAdjuntoNotificacion(item) {
      this.adjuntoPdf = null;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
      const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
      const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${'Adjuntos'}/${item.adjunto}`;
      this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
      .then(response => {
        this.dialogoAdjuntoNotificacion = true;
        this.adjuntoPdf = "data:application/pdf;base64," + response.data;
      }).catch(error => {
        this.observarError(error);
      })
    },
    /**
     * Cierra la vista del archivo de la notificación
     */
    cerrarAdjunto(){
      this.dialogoAdjuntoNotificacion = false;
      setTimeout(() => {
        this.adjuntoPdf = null;
      }, 300)
    },
    /**
     * Descarga el archivo de la notificación y cierra la vista previa del archivo
     */
    descargarAdjunto() {
      const base64Pdf = this.adjuntoPdf.split(',')[1];
      const byteCharacters = atob(base64Pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      const fileName = `Notificación-${formattedDate}.pdf`;

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
      this.cerrarAdjunto();
    },
    /**
     * Restablece los valores relacionados con los documentos
     */
    reset() {
      this.documentos.documento = null;
      this.documentos.formulaMedica = [];
      this.documentos.autorizacionMedica = [];
      this.documentos.comprobante = null;
      this.documentos.documentoFaltante = [];
      this.documentosTemporales.documento = null;
      this.documentosTemporales.formulaMedica = [];
      this.documentosTemporales.autorizacionMedica = [];
      this.documentosTemporales.comprobante = null;
      this.documentosTemporales.documentoFaltante = [];
      this.formatoIncorrecto.documento = '';
      this.formatoIncorrecto.formulaMedica = [];
      this.formatoIncorrecto.autorizacionMedica = [];
      this.formatoIncorrecto.comprobante = '';
      this.formatoIncorrecto.documentoFaltante = []; 
      this.documentosRequeridos = '';
      this.sinDocumentosRequeridos = false;
    },
    /**
     * Cierra el modal donde se visualiza el documento seleccionado
     * @param campo - nombre del modal abierto.
     */
    cerrarDialogoDoc(campo) {
      this[campo] = false;
      if (campo === 'mostarFormula') {
        this.borrarReferencias('formulaMedica', 'formulaMedicaFile');
        this.documentos.formulaMedica = [];
        this.formatoIncorrecto.formulaMedica = [];
        this.documentosTemporales.formulaMedica = [];
      }
      if (campo === 'mostarAutorizacion') {
        this.borrarReferencias('autorizacionMedica', 'autorizacionMedicaFile');
        this.documentos.autorizacionMedica = [];
        this.formatoIncorrecto.autorizacionMedica = [];
        this.documentosTemporales.autorizacionMedica = [];
      }
      if (campo === 'mostarDocumento') {
        if (this.$refs.documentoIdentidadFile) {
          this.$refs.documentoIdentidadFile.reset();
          this.documentos.documento = null;
          this.formatoIncorrecto.documento = null;
          this.documentosTemporales.documento = null;
        }
      }
      if (campo === 'mostrarComprobante') {
        if (this.$refs.comprobanteFile) {
          this.$refs.comprobanteFile.reset();
          this.documentos.comprobante = null;
          this.formatoIncorrecto.comprobante = null;
          this.documentosTemporales.comprobante = null;
        }
      }
    },
    /**
     * Restablece los valores relacionados con la visualización de los documentos
     * y los campos de entrada de archivos si existen.
     */
    cerrarDocumento() {
      this.mostrarModal = false;
      this.dialogTitulo = '';
      this.documentoSeleccionado = null;
      this.tipoDocumentoActual = '';
      this.mostarDocumento = false;
      this.mostarFormula = false;
      this.mostarAutorizacion = false;
      this.mostrarComprobante = false;

      this.borrarReferencias('formulaMedica', 'formulaMedicaFile');
      this.borrarReferencias('autorizacionMedica', 'autorizacionMedicaFile');
      if (this.solicitudSeleccionada && this.solicitudSeleccionada.documentoFaltante) {
        this.borrarReferenciasFaltante('documentoFaltante', 'documentoFaltanteFile', this.solicitudSeleccionada);
      }

      if (this.$refs.documentoIdentidadFile) {
        this.$refs.documentoIdentidadFile.reset();
      }
      if (this.$refs.comprobanteFile) {
        this.$refs.comprobanteFile.reset();
      }
      this.solicitudSeleccionada = {};
    },
    /**
     * Resetea las referencias de archivos asociados a los campos de autorización o fórmula médica.
     * 
     * @param type El tipo de campo ('autorizacionMedica' o 'formulaMedica').
     * @param ref El prefijo de la referencia del campo de archivo a resetear.
     */
    borrarReferencias(type, ref) {
      this.$nextTick(() => {
        this.documentos[type].forEach((item, index) => {
          const refArchivo = this.$refs[ref + index];
          if (refArchivo && refArchivo[0]) {
            refArchivo[0].reset();
          }
        });
      });
    },
    /**
     * Resetea las referencias de archivos asociados a los campos de faltante.
     * 
     * @param type El tipo de campo ('documentoFaltante').
     * @param ref El prefijo de la referencia del campo de archivo a resetear.
     */
    borrarReferenciasFaltante(type, ref, soli) {
      this.$nextTick(() => {
        soli[type].forEach((item, index) => {
          const refArchivo = this.$refs[ref + index];
          if (refArchivo && refArchivo[0]) {
            refArchivo[0].reset();
          }
        });
      });
    },
    /**
     * Valida el tipo de dirección "Urbana o Rural".
     * De acuerdo al tipo decide como mostrar la direción.
     * @param {*} item La solicitud a domicilio a validar.
     */
    consultarTipoDirecion(item) {
      const esDireccionUrbana = this.tiposVias.some(opcion => item.direccionEntrega.startsWith(opcion));
      if (esDireccionUrbana) {
        return `${item.direccionEntrega}, ${item.barrio !== null ? item.barrio.barrio + ', ' : ''} ${item.ciudad.ciudad}, ${item.departamento.departamento}`
      } else {
        return `${item.direccionEntrega}, ${item.ciudad.ciudad}, ${item.departamento.departamento}`
      } 
    },
    /**
     * Establece la propiedad en true cuando la pantalla tiene un ancho igual o menor a 959
     */
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 1064;
      if (window.innerWidth <= 740) {
        this.sizeText = 5
      } else {
        this.sizeText = 25
      }
    },
    /**
     * Maneja errores capturados durante solicitudes al servidor.
     * Si el error es una 'Network Error' o es una respuesta con estado 401, establece el estado de error en el almacenamiento.
     * Si el error no corresponde a los casos anteriores, se registra en la consola.
     */
    observarError(error) {
      if (error.message === 'Network Error') {
        this.$store.commit('setError', true);
      } else if (error.response && error.response.status === 401) {
        this.$store.commit('setError', true);
      } else {
        this.manejarError(error);
      }
    },
    /**
     * Muestra una notificación global en el componente.
     *
     * @param {string} mensaje - Mensaje que se mostrará en la notificación.
     * @param {string} tipo - Tipo de la notificación (por defecto, "error").
     * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
     */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000); 
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505"; 
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {
      if (error.response) {
        if (error.response.status === 503) {
          this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
        } else {
          this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
        }
      } else {
        this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
      }
    },
    /**
     * Obtiene la clase CSS correspondiente a un estado específico para resaltar su apariencia.
     */
    obtenerClaseEstado(estado) {
      const clases = {
        'CREATE': 'blue--text font-weight-bold',
        'VALIDATED': 'green--text font-weight-bold',
        'INVOICED': 'cyan--text font-weight-bold',
        'DISPATCHED': 'cyan--text font-weight-bold',
        'RESOLVED': 'cyan--text font-weight-bold',
        'REFUSED': 'red--text font-weight-bold',
        'CLOSE': 'green--text font-weight-bold',
        'OPEN': 'orange--text font-weight-bold',
        'NOVELTY': 'red--text font-weight-bold',
      };
      return clases[estado] || '';
    },
    /**
     * Renderiza un archivo PDF desde una cadena Base64 y lo dibuja en un contenedor usando canvas.
     * Convierte los datos Base64 en un ArrayBuffer, luego usa la biblioteca pdfjsLib para obtener
     * el documento PDF y renderizar cada página en un canvas dentro del contenedor.
     * 
     * @param {string} base64Data - La cadena Base64 que representa el archivo PDF.
     */
    async embedPDF(base64Data) {        
      const pdfContainer = this.$refs.pdfContainer;
      pdfContainer.innerHTML = ""; 

      try {
        const pdfData = this.base64ToArrayBuffer(base64Data);

        const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 0.7 });

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          pdfContainer.appendChild(canvas);

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
        }
      } catch (error) {
        this.observarError(error);
      }
    },
    /**
     * Convierte una cadena Base64 en un ArrayBuffer que contiene los datos binarios del PDF.
     * Esta función decodifica la cadena Base64 en una secuencia de bytes que pueden ser utilizados
     * para procesar archivos binarios, como un PDF.
     * 
     * @param {string} base64 - La cadena Base64 que representa el archivo PDF.
     * @returns {ArrayBuffer} Un ArrayBuffer con los datos binarios del archivo PDF.
     */
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>

.pagination-container {
  position: fixed;
  bottom: 0.5rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.9);
  border-top: 1px solid rgba(0, 0, 0, 0.1); 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; 
}
.fondoDialog {
background-color: #1867c0;
color: white;
}
.formulario-flex {
flex-wrap: nowrap;
}
@media (max-width: 700px) {
.formulario-flex {
  flex-wrap: wrap !important;
}
}
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
.resultadoLista {
  max-height: 12rem;
  overflow-y: auto;
  z-index: 999;
  width: 100%;
}
::v-deep .file .v-input__slot {
  padding-left: 0.3rem !important;
} 
::v-deep .file .v-text-field__prefix {
  background-color: #0d48a1dc;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
  margin-right: 0.3rem;
  color: #ffffff;
}
.boton-actualizar {
  background-color: #0d47a1 !important;
  color: #fff;
  border-radius: 18px;
}
.container {
  padding: 0px;
  margin: 0 0 0 0;
  max-width: none !important;
  width: 100% !important;
}
.encabezadoCard {
  margin: 0 !important;
}
.encabezadoCard .v-card {
  padding: 3px;
}
.pagination {
  margin-top: 0;
}
.pagination-text {
  font-size: 13px;
}
.pdfClose {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.pdfClose>p {
  width: 100%
}
.boxPdf {
  overflow: auto;
  height: 73vh;
}
.boxPdf>span {
  width: 100%;
}
.iconPdfIframe {
  zoom: 3;
}
.height-table {
  height: 70px;
}
.table {
  border: 1px solid rgb(205, 205, 205) !important;
}
table thead {
  display: block;
  width: 100%;
  background: #c7c4c4;
}
table tbody {
  display: block;
  width: 100%;
  overflow-y: overlay;
  overflow-x: auto;
}
table tbody tr,
table thead tr {
  width: 100%;
  display: table;
}
table tbody tr td,
table thead tr th {
  display: table-cell;
  padding: 1px 2px;
  overflow: hidden;
  border-right: 1px solid #d4d4d4;
  word-wrap: break-word !important;
  word-break: break-all !important;
}
.tableTrazabilidad tbody tr td,
table thead tr th {
  padding: 3px !important;
}
table tbody tr td {
  font-size: 10px !important;
}
table tbody tr td:nth-last-child(1),
table thead tr th:nth-last-child(1) {
  border-right: none !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30vw;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
.ver-observacion {
  color: #0d47a1;
  cursor: pointer;
  font-size: 0.90rem;
  text-decoration: underline;
  padding: 0 !important;
  text-transform: lowercase;
}
.visor-pdf {
  width: 100%;
  height: 51vh;
}
.pdf-chrome {
  width: 99%;
  height: 50vh;
} 
</style>